<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      contract: {
        loading: true,
        errored: false,

        id: this.$route.params.id,
        status: 'inactive',
        type: '',
        date: '',
        iptv: {
          username: '',
          password: '',
        },
        expiration: '',
        monthlies: null,
      },

      renew: {
        modal: false,
        message: null,
      }
    };
  },
  methods: {
    showDate (date) {
      this.date = date
    },
    getContract() {
      api
        .get('contracts/' + this.contract.id)
        .then(response => {
          if (response.data.status=='success') {
            this.contract.id         = response.data.contract.id
            this.contract.status     = response.data.contract.status
            this.contract.type       = response.data.contract.type
            this.contract.date       = response.data.contract.date
            this.contract.iptv       = response.data.contract.iptv
            this.contract.expiration = response.data.contract.expiration
            this.contract.monthlies  = response.data.contract.monthlies
          }
        })
        .catch(error => {
          if (error) {
            this.$router.push('/contracts')
          }
        })
        .finally(() => {
          this.contract.loading = false
        })
    },
    renewContract() {
      this.renew.message = null

      api
        .get('contracts/' + this.contract.id + '/renew')
        .then(response => {
          if (response.data.status=='success') {
            this.renew.modal = false
            this.$router.push('/store/order/' + response.data.order.id)
          } else {
            this.renew.message = response.data.message
          }
        })
    }
  },
  mounted() {
    this.getContract()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Meus Planos › Ver') }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-primary rounded-top text-white">
            <div class="row">
              <div class="col-4 text-left">#{{ contract.id }}</div>
              <div class="col-4 text-center font-weight-bold">{{ contract.type }}</div>
              <div class="col-4 text-right font-size-12 align-self-center">{{ contract.date }}</div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mt-3 mb-4 text-center">
              <div class="col-md-4" style="line-height: 16px;">
                <span class="font-size-18">
                  <template v-if="contract.status == 'active'">ATIVO</template>
                  <template v-else>INATIVO</template>
                </span><br>
                <small>STATUS</small>
              </div>
              <div class="col-md-4 text-center" style="line-height: 16px;">
                <span class="font-size-18">
                  <template v-if="contract.expiration != '31/12/1969'">{{ contract.expiration }}</template>
                  <template v-else>–</template>
                </span><br>
                <small>PRÓXIMO VENCIMENTO</small>
              </div>
              <div class="col-md-4 text-right" style="line-height: 16px;">
                <button v-if="contract.expiration != '31/12/1969'" class="btn btn-primary btn-rounded" v-on:click="renew.modal = true">
                  {{ t('Renovar') }}
                </button>
              </div>
            </div>

            <template v-if="contract.iptv.username && contract.iptv.password">
              <div class="monthlies mt-1 mb-3">
                <span>Dados de Acesso</span>
              </div>
              <div class="row justify-content-center pb-3">
                <div class="col-md-3 py-2 text-center">
                  <small>USUÁRIO</small> <strong class="bg-soft-secondary rounded p-1">{{ contract.iptv.username }}</strong>
                  <button class="btn btn-link p-0 ml-2" v-clipboard:copy="contract.iptv.username">
                    <i class="bx bx-copy font-size-18 text-dark"></i>
                  </button><br>
                </div>
                <div class="col-md-3 py-2 text-center">
                  <small>SENHA</small> <strong class="bg-soft-secondary rounded p-1">{{ contract.iptv.password }}</strong>
                  <button class="btn btn-link p-0 ml-2" v-clipboard:copy="contract.iptv.password">
                    <i class="bx bx-copy font-size-18 text-dark"></i>
                  </button><br>
                </div>
              </div>
            </template>

            <div class="monthlies mt-1 mb-3">
              <span>Mensalidades</span>
            </div>

            <div class="table-responsive">
              <div v-if="!contract.monthlies" class="text-center">
                Nenhuma mensalidade encontrada.
              </div>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Pacote</th>
                    <th>Ínicio</th>
                    <th>Final</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,index) in contract.monthlies" :key="index">
                    <td class="notranslate align-middle">{{ row.id }}</td>
                    <td class="notranslate align-middle">{{ row.package }}</td>
                    <td class="notranslate align-middle">{{ row.date.start }}</td>
                    <td class="notranslate align-middle">{{ row.date.end }}</td>
                    <td class="notranslate align-middle">{{ row.value | currency }}</td>
                    <td class="notranslate align-middle">
                      <span v-if="row.status === 'pending'" class="badge badge-soft-warning font-size-12">PENDENTE</span>
                      <span v-else-if="row.status === 'paid'" class="badge badge-soft-success font-size-12">PAGO</span>
                      <span v-else-if="row.status === 'canceled'" class="badge badge-soft-danger font-size-12">CANCELADO</span>
                    </td>
                    <td class="notranslate text-right">
                      <router-link :to="'/store/order/' + row.order" class="btn btn-primary btn-sm btn-rounded">
                        {{ t('Acessar') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="renew.modal" :title="t('Renovar')" hide-footer centered>
      <div class="text-center">
        <h5 class="mb-4">Deseja realmente gerar a renovação do seu plano?</h5>
        <button class="btn btn-secondary btn-sm mr-2">NÃO</button>
        <button class="btn btn-default btn-lg" v-on:click="renewContract()">SIM</button>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.monthlies {
  position: relative;
  text-align: center;
  z-index: 0;
}
.monthlies span {
  display: inline-block;
  padding: 0 10px;
  background: white;
  font-size: .8em;
  text-transform: uppercase;
}
.monthlies:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #666;
}
</style>